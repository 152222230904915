import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import FormikFileInput from '../../components/formik/FormikFileInput';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { createEmployee, getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import { ClipLoader } from 'react-spinners';
import { ROLES } from '../../utils/dropdownOptions';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddEmployee = () => {
  const { loading } = useSelector(getEmployees);
  const dispatch = useDispatch();
  const { user } = useSelector(getAuth);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '91',
      password: '',
      role: '',
      status: true,
    },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      username: string().required(),
      password: string().required(),
      role: string().required(),
    }),
    onSubmit: async values => {
      dispatch(createEmployee(values));
    },
  });
  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikInputGroup name="firstName" label="First Name" formik={formik} required />
        <FormikInputGroup name="lastName" label="Last Name" formik={formik} required />

        <FormikPhoneInputGroup name="username" label="Phone Number" formik={formik} required />
        <FormikInputGroup
          name="password"
          type="password"
          label="Password"
          formik={formik}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label="Role"
          name="role"
          required
          options={generateOptions({
            array: user.role == 'admin' ? ROLES : ROLES.filter(d => d.value !== 'admin'),
            valueField: 'value',
            labelField: 'label',
          })}
        />
        <FormikCheckbox name="status" label="Status" formik={formik} />

        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};

export default AddEmployee;
