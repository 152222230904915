import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { useFormik } from "formik";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { ClipLoader } from "react-spinners";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import useQueryString from "../../customHook/useQueryString";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";

const ViewProductByParentSku = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filteredDataBySku, setFilteredDataBySku] = useState([]);
  const { parentSku } = useParams();
  const { baseUrl } = useSelector(getMediaLibrary);
  const { location } = useSelector(getLocation);
  const locationInfoId = JSON.parse(localStorage.getItem("locationInfoId"));
  const dispatch = useDispatch();
  const query = useQueryString();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { location: query.location ?? locationInfoId },
    onSubmit: (values) => {},
  });

  useEffect(() => {
    dispatch(fetchLocations());
    getFilteredDataBySku();
  }, [parentSku, JSON.stringify(formik.values.location)]);

  const getFilteredDataBySku = async () => {
    try {
      setIsLoading(true);
      const filterQuery = QueryString.stringify({
        filteredLocationArray: formik?.values?.location,
        parentSku: parentSku,
      });
      const response = await authAxiosInstance.get(
        `dashboard/parentSku-wise-data?${filterQuery}`
      );
      if (response) {
        const respData =
          response?.data?.data?.productInventoryFilteredDataByParentSku;
        if (respData) {
          setFilteredDataBySku(respData);
        } else {
          setFilteredDataBySku([]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "SRNo",
      Cell: ({ row }) => {
        console.log(row, "row");
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: "relative" }}>
              <img
                src={`${baseUrl}${row.original?.product?.photo}`}
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </>
        );
      },
    },
    {
      Header: "Master Sku",
      accessor: "product.masterSku",
    },
    {
      Header: "Cost Price",
      accessor: "product.costPrice",
    },
    {
      Header: "Received",
      accessor: "currentReceivedData",
    },
    {
      Header: "Damaged",
      accessor: "currentDamagedData",
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
  ];

  return (
    <PageWithCard heading="Product By ParentSku">
      {isLoading ? (
        <ClipLoader />
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <FormikMultiSelect
              label="Selected Location"
              name="location"
              formik={formik}
              options={generateOptions({
                array: location?.docs ?? [],
                labelField: "name",
                valueField: "_id",
              })}
            />
          </form>
          <div className="mt-3">
            <TableWithHeadingAndSearch
              data={filteredDataBySku}
              columns={columns}
            />
          </div>
        </>
      )}
    </PageWithCard>
  );
};

export default ViewProductByParentSku;
