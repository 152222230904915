import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/Auth/authSlice';
import mediaLibrarySlice from './reducers/MediaLibrary/mediaLibrarySlice';
import emailSlice from './reducers/Emails/emailSlice';
import usersSlice from './reducers/Users/usersSlice';
import EmployeeSlice from './reducers/Users/EmployeeSlice';
import VendorSlice from './reducers/Users/VendorSlice';
import CustomerSlice from './reducers/Users/CustomerSlice';
import colorSlice from './reducers/Color/colorSlice';
import sizeSlice from './reducers/Size/sizeSlice';
import productSlice from './reducers/Product/productSlice';
import attributeTypeSlice from './reducers/AttributeType/attributeTypeSlice';
import attributeSlice from './reducers/Attribute/attributeSlice';
import locationSlice from './reducers/Location/locationSlice';
import productPurchaseOrderSlice from './reducers/ProductPurchaseOrder/productPurchaseOrderSlice';
import productChallanSlice from './reducers/ProductChallan/productChallanSlice';
import productInventorySlice from './reducers/ProductInventory/productInventorySlice';
import generalBulkUploadSlice from './reducers/GeneralBulkUpload/generalBulkUploadSlice';
import saleSlice from './reducers/Sale/saleSlice';
import saleReturnSlice from './reducers/SaleReturn/saleReturnSlice';
import deadStockPercentageSlice from './reducers/DeadStockPercentage/deadStockPercentageSlice';
import stockAdjustmentSlice from './reducers/StockAdjustment/stockAdjustmentSlice';
import confirmPopUpSlice from './reducers/ConfirmPopUp/confirmPopUpSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    mediaLibrary: mediaLibrarySlice,
    emails: emailSlice,
    users: usersSlice,
    employees: EmployeeSlice,
    vendor: VendorSlice,
    customer: CustomerSlice,
    color: colorSlice,
    size: sizeSlice,
    product: productSlice,
    attributeType: attributeTypeSlice,
    attribute: attributeSlice,
    location: locationSlice,
    productPurchaseOrder: productPurchaseOrderSlice,
    productChallan: productChallanSlice,
    productInventory: productInventorySlice,
    generalBulkUpload: generalBulkUploadSlice,
    sale: saleSlice,
    saleReturn: saleReturnSlice,
    deadStock: deadStockPercentageSlice,
    stockAdjustment: stockAdjustmentSlice,
    confirmPopUp: confirmPopUpSlice,
  },
});
