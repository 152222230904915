import Dashboard from '../pages/Dashboard';
import AddAttribute from '../pages/Master/AddAttribute';
import AddAttributeType from '../pages/Master/AddAttributeType';
import AddLocation from '../pages/Location/AddLocation';
import EditAttribute from '../pages/Master/EditAttribute';
import ViewAttribute from '../pages/Master/ViewAttribute';
import ViewLocation from '../pages/Location/ViewLocation';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import AddProduct from '../pages/Product/AddProduct';
import AddProductChallan from '../pages/Product/AddProductChallan';
import EditProduct from '../pages/Product/EditProduct';
import ProductPurchaseOrder from '../pages/Product/ProductPurchaseOrder';
import ReceiveProductPurchaseOrder from '../pages/Product/ReceiveProductPurchaseOrder';
import ViewProduct from '../pages/Product/ViewProduct';
import ViewProductPurchaseOrder from '../pages/Product/ViewProductPurchaseOrder';
import AddEmployee from '../pages/Users/AddEmployee';
import AddVendor from '../pages/Users/AddVendor';
import EditEmployee from '../pages/Users/EditEmployee';
import EditEmployeePage from '../pages/Users/EditEmployeePage';
import EditVendor from '../pages/Users/EditVendor';
import ViewEmployees from '../pages/Users/ViewEmployees';
import ViewVendor from '../pages/Users/ViewVendor';
import AssignLocation from '../pages/Location/AssignLocation';
import ViewChallanByPurchaseOrder from '../pages/Product/ViewChallanByPurchaseOrder';
import ViewVendorWisePurchaseOrder from '../pages/Product/ViewVendorWisePurchaseOrder';
import ViewProductPurchaseOrderByVendor from '../pages/Product/ViewProductPurchaseOrderByVendor';
import ViewProductByParentSku from '../pages/Product/ViewProductByParentSku';
import DirectProductPurchaseOrder from '../pages/Product/DirectProductPurchaseOrder';
import ViewDirectProductPurchaseOrder from '../pages/Product/ViewDirectProductPurchaseOrder';
import EditDirectProductPurchaseOrder from '../pages/Product/EditDirectProductPurchaseOrder';
import AddCustomer from '../pages/Users/AddCustomer';
import ViewCustomer from '../pages/Users/ViewCustomer';
import EditCustomer from '../pages/Users/EditCustomer';
import Sale from '../pages/Sale/Sale';
import ViewSale from '../pages/Sale/ViewSale';
import ViewSaleProducts from '../pages/Sale/ViewSaleProducts';
import SaleReturn from '../pages/Sale/SaleReturn';
import ViewSaleReturn from '../pages/Sale/ViewSaleReturn';
import ViewSaleReturnProducts from '../pages/Sale/ViewSaleReturnProducts';
import GenerateBarcode from '../pages/GenerateBarcode/GenerateBarcode';
import UniversalSearch from '../pages/UniversalSearch/UniversalSearch';
import GeneralBulkUpload from '../pages/generalBulkUpload/GeneralBulkUpload';
import CustomerSalesReport from '../pages/Reports/CustomerSalesReport';
import CustomerWiseProductReport from '../pages/Reports/CustomerWiseProductReport';
import ProductSalesReport from '../pages/Reports/ProductSalesReport';
import StockReport from '../pages/Reports/StockReport';
import ProductSalesByParentSkuReport from '../pages/Reports/ProductSalesByParentSkuReport';
import ProductReturnReport from '../pages/Reports/ProductReturnReport';
import InventoryPage from '../pages/Inventory/InventoryPage';
import DeadStockPercentage from '../pages/DeadStockPercentage/DeadStockPercentage';
import EditDeadStockPercentage from '../pages/DeadStockPercentage/EditDeadStockPercentage';
import DeadStockReport from '../pages/Reports/DeadStockReport';
import ScanInwardPage from '../pages/ScanInward/ScanInwardPage';
import AddStockAdjustment from '../pages/StockAdjustment/AddStockAdjustment';
import ViewStockAdjustment from '../pages/StockAdjustment/ViewStockAdjustment';
import { ROLES } from './roles';

export const routes = [
  {
    route: '/',
    element: <Dashboard />,
    allowedRoles: ROLES.ALL,
  },
  //dashboard Internal
  {
    route: '/dashboard/viewProductByParentSku/:parentSku',
    element: <ViewProductByParentSku />,
    allowedRoles: ROLES.ALL,
  },
  {
    route: '/dashboard/viewVendorWisePurchaseOrder',
    element: <ViewVendorWisePurchaseOrder />,
    allowedRoles: ROLES.ALL,
  },
  {
    route: '/dashboard/viewVendorWisePurchaseOrder/viewProductPurchaseOrderByVendor/:id',
    element: <ViewProductPurchaseOrderByVendor />,
    allowedRoles: ROLES.ALL,
  },

  //users//employees/vendors
  {
    route: '/users/add-employee',
    element: <AddEmployee />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/edit-employee',
    element: <EditEmployee />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/edit-employee/:id',
    element: <EditEmployeePage />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/view-employee',
    element: <ViewEmployees />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/add-vendor',
    element: <AddVendor />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/view-vendor',
    element: <ViewVendor />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/edit-vendor/:id',
    element: <EditVendor />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/add-customer',
    element: <AddCustomer />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/view-customer',
    element: <ViewCustomer />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/users/edit-customer/:id',
    element: <EditCustomer />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  //media-library
  {
    route: '/media-library',
    element: <MediaLibrary />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/media-library/*',
    element: <MediaLibrary />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  //master
  {
    route: '/master/addAttributeType',
    element: <AddAttributeType />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/master/addAttribute',
    element: <AddAttribute />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/master/viewAttribute',
    element: <ViewAttribute />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/master/editAttribute/:id',
    element: <EditAttribute />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },

  //product
  {
    route: '/product/addProduct',
    element: <AddProduct />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/viewProduct',
    element: <ViewProduct />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/editProduct/:id',
    element: <EditProduct />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/createProductPurchaseOrder',
    element: <ProductPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/viewProductPurchaseOrder',
    element: <ViewProductPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/receiveProductPurchaseOrder',
    element: <ReceiveProductPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/directProductPurchaseOrder',
    element: <DirectProductPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/viewDirectProductPurchaseOrder',
    element: <ViewDirectProductPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/editDirectProductPurchaseOrder/:id',
    element: <EditDirectProductPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/createProductChallan/:id',
    element: <AddProductChallan />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  {
    route: '/product/viewChallanByPurchaseOrder/:id',
    element: <ViewChallanByPurchaseOrder />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  //location
  {
    route: '/location/addLocation',
    element: <AddLocation />,
    allowedRoles: ROLES.ALL,
  },
  {
    route: '/location/viewLocation',
    element: <ViewLocation />,
    allowedRoles: ROLES.ALL,
  },
  {
    route: '/location/assignLocation',
    element: <AssignLocation />,
    allowedRoles: ROLES.ALL,
  },
  //sale
  {
    route: '/sale/addSale',
    element: <Sale />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/sale/viewSale',
    element: <ViewSale />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/sale/viewSale/:id',
    element: <ViewSaleProducts />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/sale/addSaleReturn',
    element: <SaleReturn />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/sale/viewSaleReturn',
    element: <ViewSaleReturn />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/sale/viewSaleReturn/:id',
    element: <ViewSaleReturnProducts />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  //generateBarcode

  {
    route: '/generateBarcode',
    element: <GenerateBarcode />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },

  //universalSearch
  {
    route: '/universalSearch',
    element: <UniversalSearch />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },

  //inventoryPage
  {
    route: '/inventoryPage',
    element: <InventoryPage />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },

  //deadStockPage
  {
    route: '/deadStockPercentage',
    element: <DeadStockPercentage />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/deadStockPercentage/:id',
    element: <EditDeadStockPercentage />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },

  //stockInwardPage
  {
    route: '/scanInward',
    element: <ScanInwardPage />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  //generalBulKUpload
  {
    route: 'generalBulkUpload',
    element: <GeneralBulkUpload />,
    allowedRoles: ROLES.PRODUCT_ENTITY,
  },
  //reports
  {
    route: '/report/customerSales',
    element: <CustomerSalesReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/report/productSales',
    element: <ProductSalesReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/report/customerWiseProduct',
    element: <CustomerWiseProductReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/report/stock',
    element: <StockReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/report/productSalesByParentSku',
    element: <ProductSalesByParentSkuReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/report/productReturnSales',
    element: <ProductReturnReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  {
    route: '/report/deadStock',
    element: <DeadStockReport />,
    allowedRoles: ROLES.INVENTORY_ENTITY,
  },
  //stockAdjustment
  {
    route: '/stockAdjustment/addStockAdjustment',
    element: <AddStockAdjustment />,
    allowedRoles: ROLES.ADMIN,
  },
  {
    route: '/stockAdjustment/viewStockAdjustment',
    element: <ViewStockAdjustment />,
    allowedRoles: ROLES.ADMIN,
  },
];
