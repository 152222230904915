import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProduct,
  fetchProduct,
  getProducts,
  resetProductData,
} from '../../app/reducers/Product/productSlice';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import ProductAttributes from './ProductAttributes';
import { fetchAttributeTypes } from '../../app/reducers/AttributeType/attributeTypeSlice';
import { ClipLoader } from 'react-spinners';

const EditProduct = () => {
  const { id } = useParams();
  const { elementEditData, editDataLoading } = useSelector(getProducts);
  const dispatch = useDispatch();

  const computeEditData = data => {
    const attributeArray = data?.attributes?.map((el, i) => ({
      type: el?.type?._id,
      attributeName: el?._id,
    }));
    return {
      ...data,
      attributes: attributeArray,
    };
  };

  useEffect(() => {
    dispatch(fetchProduct({ _id: id, populate: true }));
    dispatch(fetchAttributeTypes());
    return () => {
      dispatch(resetProductData());
    };
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: computeEditData(elementEditData),
    onSubmit: async values => {
      const attributeIds = values?.attributes?.map(el => el?.attributeName);
      const payload = {
        ...values,
        attributes: attributeIds,
      };
      try {
        await dispatch(editProduct(payload));
        await dispatch(fetchProduct({ _id: id, populate: true }));
        formik.resetForm();
      } catch (error) {
        console.log(error, 'error');
        toast.error('Failed to edit Product');
      }
    },
  });

  return (
    <PageWithCard heading="Edit Product">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <FormikInputGroup label="Name" name="name" formik={formik} required />

          {formik?.values?.name && (
            <FormikProvider value={formik}>
              <FieldArray
                name="attributes"
                render={arrayHelpers => {
                  return (
                    <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
                      <h1 className="text text-lg font-bold">Add Attributes</h1>
                      <div>
                        {formik.values.attributes.map((ele, index) => (
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: '1px solid #d6c7c7',
                              borderRadius: '5px',
                            }}
                            key={index}
                          >
                            {/* This component is common for AddProduct and EditProduct */}
                            <ProductAttributes
                              formik={formik}
                              index={index}
                              arrayHelpers={arrayHelpers}
                            />
                          </div>
                        ))}
                      </div>
                      <div>
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push({});
                          }}
                          type="button"
                        >
                          Add More
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          )}

          {formik?.values?.name && (
            <FormikDirectFileUpload
              formik={formik}
              label="Upload Photo"
              name="photo"
              location={`vaniya/product/${formik?.values?.name}/`}
              required
            />
          )}

          <FormikInputGroup label="Cost Price" name="costPrice" formik={formik} required />
          <FormikInputGroup label="Master Sku" name="masterSku" formik={formik} required />

          {formik?.values?.masterSku && (
            <FormikProvider value={formik}>
              <FieldArray
                name="additionalSku"
                render={arrayHelpers => {
                  return (
                    <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
                      <h1 className="text text-lg font-bold">Add Additional Sku</h1>
                      <div>
                        {formik.values.additionalSku.map((ele, index) => (
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: '1px solid #d6c7c7',
                              borderRadius: '5px',
                            }}
                            key={index}
                          >
                            <div
                              className="relative p-4 mb-2"
                              style={{
                                border: '1px solid #d6c7c7',
                                borderRadius: '5px',
                              }}
                            >
                              <FormikInputGroup
                                label="Platform"
                                formik={formik}
                                name={`additionalSku.${index}.platform`}
                                required
                              />
                              <FormikInputGroup
                                label="Sku"
                                formik={formik}
                                name={`additionalSku.${index}.sku`}
                                required
                              />
                            </div>
                            <div>
                              <DangerButton
                                className="mt-3"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                type="button"
                              >
                                Remove
                              </DangerButton>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push({});
                          }}
                          type="button"
                        >
                          Add More
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          )}
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};
export default EditProduct;
