import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColors, getColors } from '../../app/reducers/Color/colorSlice';
import { fetchSizes, getSizes } from '../../app/reducers/Size/sizeSlice';
import { useEffect } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import * as Yup from 'yup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { createProduct, fetchProducts, getProducts } from '../../app/reducers/Product/productSlice';
import { fetchAttributes, getAttributes } from '../../app/reducers/Attribute/attributeSlice';
import { getVendors, fetchVendors } from '../../app/reducers/Users/VendorSlice';
import ProductAttributes from './ProductAttributes';
import { toast } from 'react-toastify';
import { fetchAttributeTypes } from '../../app/reducers/AttributeType/attributeTypeSlice';
import { ClipLoader } from 'react-spinners';

const AddProduct = () => {
  const { vendor } = useSelector(getVendors);
  const { product } = useSelector(getProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
    dispatch(fetchAttributeTypes());
    dispatch(fetchProducts());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      photo: '',
      parentSku: '',
      masterSku: '',
      additionalSku: [],
      costPrice: 0,
      attributes: [],
      manufacturer: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      photo: Yup.string(),
      parentSku: Yup.string().required(),
      masterSku: Yup.string().required(),
      costPrice: Yup.number().min(0),
      additionalSku: Yup.array().of(
        Yup.object({
          platform: Yup.string(),
          sku: Yup.string(),
        })
      ),
      attributes: Yup.array().required(),
      manufacturer: Yup.array().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const attributeIds = values?.attributes?.map(el => el?.attributeName);
      let currentCombination = '';
      let canSubmit = true;
      values?.attributes?.forEach(ele => {
        let newCombination = `${ele.type}/${ele.attributeName}`;
        if (newCombination === currentCombination) {
          canSubmit = false;
          return alert('Duplicate attributes');
        } else {
          currentCombination = newCombination;
        }
      });
      if (!canSubmit) {
        return;
      }
      const payload = {
        ...values,
        attributes: attributeIds,
      };
      try {
        const existingMasterSku = product?.docs?.find(el => el?.masterSku === values?.masterSku);
        if (existingMasterSku) {
          alert('Duplicate Master Sku');
        } else {
          await dispatch(createProduct(payload));
          await resetForm();
        }
      } catch (error) {
        console.log(error, 'error');
        toast.error('Failed to create Product');
      }
    },
  });
  return (
    <PageWithCard heading="Add Product">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <FormikMultiSelect
          label="Manufacturer"
          formik={formik}
          name="manufacturer"
          options={generateOptions({
            array: vendor?.docs ?? [],
            labelField: 'companyName',
            valueField: '_id',
          })}
        />
        <FormikInputGroup label="Name" name="name" formik={formik} required />

        {formik?.values?.name && (
          <FormikProvider value={formik}>
            <FieldArray
              name="attributes"
              render={arrayHelpers => {
                return (
                  <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
                    <h1 className="text text-lg font-bold">Add Attributes</h1>
                    <div>
                      {formik.values.attributes.map((ele, index) => (
                        <div
                          className="relative p-4 mb-2"
                          style={{
                            border: '1px solid #d6c7c7',
                            borderRadius: '5px',
                          }}
                          key={index}
                        >
                          <ProductAttributes
                            formik={formik}
                            index={index}
                            arrayHelpers={arrayHelpers}
                          />
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push({});
                        }}
                        type="button"
                      >
                        Add More
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
        )}

        {formik?.values?.name && (
          <FormikDirectFileUpload
            formik={formik}
            label="Upload Photo"
            name="photo"
            location={`vaniya/product/${formik?.values?.name}/`}
          />
        )}

        <FormikInputGroup label="Cost Price" name="costPrice" formik={formik} required />
        <FormikInputGroup label="Parent Sku" name="parentSku" formik={formik} required />
        <FormikInputGroup label="Master Sku" name="masterSku" formik={formik} required />

        {formik?.values?.masterSku && (
          <FormikProvider value={formik}>
            <FieldArray
              name="additionalSku"
              render={arrayHelpers => {
                return (
                  <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
                    <h1 className="text text-lg font-bold">Add Additional Sku</h1>
                    <div>
                      {formik.values.additionalSku.map((ele, index) => (
                        <div
                          className="relative p-4 mb-2"
                          style={{
                            border: '1px solid #d6c7c7',
                            borderRadius: '5px',
                          }}
                          key={index}
                        >
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: '1px solid #d6c7c7',
                              borderRadius: '5px',
                            }}
                          >
                            <FormikInputGroup
                              label="Platform"
                              formik={formik}
                              name={`additionalSku.${index}.platform`}
                              required
                            />
                            <FormikInputGroup
                              label="Sku"
                              formik={formik}
                              name={`additionalSku.${index}.sku`}
                              required
                            />
                          </div>
                          <div>
                            <DangerButton
                              className="mt-3"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              type="button"
                            >
                              Remove
                            </DangerButton>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push({});
                        }}
                        type="button"
                      >
                        Add More
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
    </PageWithCard>
  );
};
export default AddProduct;
