import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProductChallan,
  fetchProductChallans,
  getProductChallans,
} from '../../app/reducers/ProductChallan/productChallanSlice';
import { useFormik } from 'formik';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { fetchVendors, getVendors } from '../../app/reducers/Users/VendorSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { ClipLoader } from 'react-spinners';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { Edit2, Trash } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { generateBarcodeDataByQty } from '../../utils/generateBarcodeData';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';

const ViewDirectProductPurchaseOrder = () => {
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const { productChallan, loading } = useSelector(getProductChallans);
  const { location } = useSelector(getLocation);
  const { vendor } = useSelector(getVendors);
  const { baseUrl } = useSelector(getMediaLibrary);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      location: '',
      manufacturer: '',
      from: moment().startOf('day').valueOf(),
      to: moment().endOf('day').valueOf(),
    },
    onSubmit: async values => {
      let filterData = {
        status: 'directReceiveOrder',
        createdAt: {
          $gt: values?.from,
          $lt: values?.to,
        },
        populate: true,
        page,
      };
      if (values?.location) {
        filterData['location._id'] = values?.location;
      }
      if (values?.manufacturer) {
        filterData['manufacturer._id'] = values?.manufacturer;
      }
      dispatch(fetchProductChallans(filterData));
    },
  });

  useEffect(() => {
    dispatch(
      fetchProductChallans({
        status: 'directReceiveOrder',
        createdAt: {
          $gt: formik?.values?.from,
          $lt: formik?.values?.to,
        },
        populate: true,
        page,
      })
    );
    dispatch(fetchLocations());
    dispatch(fetchVendors());
  }, [page]);

  const columns = [
    {
      Header: 'Select',
      Cell: ({ row, data }) => (
        <input
          type="checkbox"
          checked={selectedRows.map(ele => ele.id).includes(row.original._id)}
          onChange={e => {
            e.preventDefault();

            let obj = {
              productDetail: {
                productName: row?.original?.product?.name,
                masterSku: row?.original?.product?.masterSku,
              },
              quantity: row?.original?.receivedQty,
              id: row?.original?._id,
            };
            if (e.target.checked) {
              setSelectedRows([...selectedRows, obj]);
            } else {
              const data = selectedRows.filter(item => item.id !== row.original._id);
              setSelectedRows(data);
            }
          }}
        />
      ),
    },
    {
      Header: 'SR.No',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.challanDate
          ? moment(row?.original?.challanDate).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'Challan No',
      accessor: 'challanNumber',
    },
    {
      Header: 'Photo',
      Cell: prop => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: 'relative' }}>
              <img
                src={`${baseUrl}${row.original?.product?.photo}`}
                style={{ width: '100px', height: '100px' }}
              />
            </div>
          </>
        );
      },
    },
    {
      Header: 'Product',
      accessor: 'product.name',
    },
    {
      Header: 'Master Sku',
      accessor: 'product.masterSku',
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Manufacturer',
      accessor: 'manufacturer.companyName',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Quantity',
      accessor: 'receivedQty',
    },
    {
      Header: 'Damaged',
      accessor: 'damaged',
    },
    {
      Header: 'Notes',
      accessor: 'notes',
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex flex-row gap-3"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={() =>
                navigate(`/product/editDirectProductPurchaseOrder/${row?.original?._id}`)
              }
            />
            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteProductChallan({ id: row?.original?._id }));
                await dispatch(
                  fetchProductChallans({
                    status: 'directReceiveOrder',
                    populate: true,
                  })
                );
              }}
            />
          </div>
        );
      },
    },
  ];

  const dataMemo = useMemo(() => {
    return productChallan?.docs ?? [];
  }, [productChallan]);

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <PageWithCard>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikSelectGroup
          options={generateOptions({
            array: location?.docs ?? [],
            labelField: 'name',
            valueField: '_id',
          })}
          name="location"
          formik={formik}
          label="Location"
        />
        <FormikSelectGroup
          options={generateOptions({
            array: vendor?.docs ?? [],
            labelField: 'companyName',
            valueField: '_id',
          })}
          name="manufacturer"
          formik={formik}
          label="Manufacturer"
        />
        <FormikInputDateGroup name="from" label="From" formik={formik} />
        <FormikInputDateGroup name="to" label="To" formik={formik} />
        <div>
          {loading || formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      {selectedRows?.length > 0 && (
        <div className="mb-3 mt-3">
          <PrimaryButton onClick={() => generateBarcodeDataByQty(selectedRows)} type="button">
            Print
          </PrimaryButton>
        </div>
      )}
      <div className="mt-3">
        <TableWithHeadingAndSearch
          heading="Direct Purchase Order Data"
          columns={columnsMemo}
          data={dataMemo}
        />
        <PaginationClassic setPage={setPage} paginationDetails={productChallan} />
      </div>
    </PageWithCard>
  );
};

export default ViewDirectProductPurchaseOrder;
