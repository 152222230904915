import {
  Archive,
  Book,
  Briefcase,
  File,
  Framer,
  Grid,
  MapPin,
  Monitor,
  PieChart,
  Search,
  Server,
  ShoppingBag,
  Tag,
  User,
  XSquare,
} from 'react-feather';
import SidebarGroup from '../../components/infrastructure/SidebarGroup';
import SidebarSingle from '../../components/infrastructure/SidebarSingle';
import SidebarTopText from '../../components/infrastructure/SidebarTopText';
import { ROLES } from '../../utils/roles';

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
  return (
    <ul className="mt-3">
      {/* Dashboard */}
      <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
      <SidebarSingle
        allowedRoles={ROLES.ALL}
        activePath="/"
        name="Dashboard"
        Icon={<Monitor />}
        sidebarOpen={sidebarOpen}
      />
      {/* inventoryPage */}
      <SidebarSingle
        allowedRoles={ROLES.INVENTORY_ENTITY}
        activePath="/inventoryPage"
        name="Inventory Page"
        Icon={<Server />}
        sidebarOpen={sidebarOpen}
      />
      {/* Users/Employee/Vendor/Customer */}
      <SidebarGroup
        links={[
          {
            label: 'Add Employee',
            link: '/users/add-employee',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'View Employee',
            link: '/users/view-employee',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Edit Employee',
            link: '/users/edit-employee',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Add Vendor',
            link: '/users/add-vendor',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'View Vendor',
            link: '/users/view-vendor',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Add Customer',
            link: '/users/add-customer',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'View Customer',
            link: '/users/view-customer',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
        ]}
        heading={'Users'}
        Icon={<User />}
        setSidebarExpanded={setSidebarExpanded}
        sidebarExpanded={sidebarExpanded}
      />
      {/* Location */}
      <SidebarGroup
        links={[
          {
            label: 'Add Location',
            link: '/location/addLocation',
            allowedRoles: ROLES.ALL,
          },
          {
            label: 'View Location',
            link: '/location/viewLocation',
            allowedRoles: ROLES.ALL,
          },
          {
            label: 'Assign Location',
            link: '/location/assignLocation',
            allowedRoles: ROLES.ALL,
          },
        ]}
        heading="Location"
        Icon={<MapPin />}
        setSidebarExpanded={setSidebarExpanded}
        sidebarExpanded={sidebarExpanded}
      />

      {/* Master */}
      <SidebarGroup
        Icon={<Grid />}
        heading={`Master`}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
        links={[
          {
            label: 'Add AttributeType',
            link: '/master/addAttributeType',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'Add Attribute',
            link: '/master/addAttribute',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'View Attribute',
            link: '/master/viewAttribute',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
        ]}
      />
      {/* universalSearch Page*/}
      <SidebarSingle
        allowedRoles={ROLES.PRODUCT_ENTITY}
        name="Universal Search"
        activePath="/universalSearch"
        sidebarOpen={sidebarOpen}
        Icon={<Search />}
      />

      {/* generateBarcode Page */}
      <SidebarSingle
        allowedRoles={ROLES.PRODUCT_ENTITY}
        name="Generate Barcode"
        activePath="/generateBarcode"
        sidebarOpen={sidebarOpen}
        Icon={<Tag />}
      />

      {/* scanInwardPage */}
      <SidebarSingle
        allowedRoles={ROLES.PRODUCT_ENTITY}
        activePath="/scanInward"
        name="Scan Inward"
        Icon={<Framer />}
        sidebarOpen={sidebarOpen}
      />

      {/* deadStockPercentagePage */}
      <SidebarSingle
        allowedRoles={ROLES.INVENTORY_ENTITY}
        activePath="/deadStockPercentage"
        name="Dead Stock Percentage"
        Icon={<XSquare />}
        sidebarOpen={sidebarOpen}
      />

      {/* stockAdjustment */}
      <SidebarGroup
        Icon={<PieChart />}
        heading="Stock Adjustment"
        setSidebarExpanded={setSidebarExpanded}
        sidebarExpanded={sidebarExpanded}
        links={[
          {
            label: 'Stock Adjustment',
            link: '/stockAdjustment/addStockAdjustment',
            allowedRoles: ROLES.ADMIN,
          },
          {
            label: 'View Stock Adjustment',
            link: '/stockAdjustment/viewStockAdjustment',
            allowedRoles: ROLES.ADMIN,
          },
        ]}
      />

      {/* Product */}
      <SidebarGroup
        Icon={<Archive />}
        heading={`Product`}
        sidebarExpanded={sidebarExpanded}
        setSidebarExpanded={setSidebarExpanded}
        links={[
          {
            label: 'Add Product',
            link: '/product/addProduct',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'View Product',
            link: '/product/viewProduct',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'Direct PurchaseOrder',
            link: '/product/directProductPurchaseOrder',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'View DirectPurchaseOrder',
            link: '/product/viewDirectProductPurchaseOrder',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'Create PurchaseOrder',
            link: '/product/createProductPurchaseOrder',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },

          {
            label: 'View PurchaseOrder',
            link: '/product/viewProductPurchaseOrder',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
          {
            label: 'Receive PurchaseOrder',
            link: '/product/receiveProductPurchaseOrder',
            allowedRoles: ROLES.PRODUCT_ENTITY,
          },
        ]}
      />
      {/* sale */}
      <SidebarGroup
        Icon={<ShoppingBag />}
        heading="Sale"
        setSidebarExpanded={setSidebarExpanded}
        sidebarExpanded={sidebarExpanded}
        links={[
          {
            label: 'Sale',
            link: '/sale/addSale',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'View Sale',
            link: '/sale/viewSale',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Sale Return',
            link: '/sale/addSaleReturn',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'View Sale Return',
            link: '/sale/viewSaleReturn',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
        ]}
      />

      {/* mediaLibrary */}
      <SidebarSingle
        allowedRoles={ROLES.PRODUCT_ENTITY}
        activePath="/media-library"
        name="Media Library"
        Icon={<Briefcase />}
        sidebarOpen={sidebarOpen}
      />
      {/* generalBulkUpload */}
      <SidebarSingle
        allowedRoles={ROLES.PRODUCT_ENTITY}
        activePath="/generalBulkUpload"
        name="General Bulk Upload"
        Icon={<File />}
        sidebarOpen={sidebarOpen}
      />
      {/* reports */}
      <SidebarGroup
        Icon={<Book />}
        heading="Reports"
        setSidebarExpanded={setSidebarExpanded}
        sidebarExpanded={sidebarExpanded}
        links={[
          {
            label: 'Customer Sales Report',
            link: '/report/customerSales',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Product Sales Report',
            link: '/report/productSales',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Product Return Report',
            link: '/report/productReturnSales',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Product Sales By ParentSku',
            link: '/report/productSalesByParentSku',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Customer Wise Product Sales Report',
            link: '/report/customerWiseProduct',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Stock Report',
            link: '/report/stock',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
          {
            label: 'Dead Stock Report',
            link: '/report/deadStock',
            allowedRoles: ROLES.INVENTORY_ENTITY,
          },
        ]}
      />
    </ul>
  );
};

export default SidebarLinks;
