import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useSelector } from 'react-redux';
import FormikAsyncMultiSelect from '../../components/formik/FormikAsyncMultiSelect';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { ClipLoader } from 'react-spinners';
import ProductAdditionalSkuModal from '../Product/ProductAdditionalSkuModal';
import ProductAttributesModal from '../Product/ProductAttributesModal';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';

const UniversalSearch = () => {
  const [isOpenAdditionalSkuModel, setIsOpenAdditionalSkuModel] = useState(false);
  const [productAdditionalSkuData, setProductAdditionalSkuData] = useState([]);
  const [isOpenAttributesModal, setIsOpenAttributesModal] = useState(false);
  const [productAttributesData, setProductAttributesData] = useState([]);
  const [filteredInventoryData, setFilteredInventoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { baseUrl } = useSelector(getMediaLibrary);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      products: [],
      parentSku: '',
    },
    onSubmit: async values => {
      try {
        if (!values.products) {
          setFilteredInventoryData([]);
          return;
        }
        setIsLoading(true);
        const filter = {
          filteredProducts: values?.products,
        };
        const response = await authAxiosInstance.post(`product/universal?`, filter);
        if (response) {
          setFilteredInventoryData(response.data.data.filteredProductInInventoryData);
        } else {
          setFilteredInventoryData([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Error fetching Products');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const columns = [
    {
      Header: 'SRNo',
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Photo',
      Cell: prop => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: 'relative' }}>
              <img
                src={`${baseUrl}${row.original?.product?.photo}`}
                style={{ width: '100px', height: '100px' }}
              />
            </div>
          </>
        );
      },
    },
    {
      Header: 'Product Name',
      accessor: 'product.name',
    },
    {
      Header: 'ParentSku',
      accessor: 'product.parentSku',
    },
    {
      Header: 'MasterSku',
      accessor: 'product.masterSku',
    },
    {
      Header: 'Cost Price',
      accessor: 'product.costPrice',
    },
    {
      Header: 'Total Received',
      accessor: 'inventory',
    },
    {
      Header: 'Additional_Sku',
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.stopPropagation();
              setIsOpenAdditionalSkuModel(true);
              setProductAdditionalSkuData(row?.original?.product?.additionalSku);
            }}
          >
            Additional Sku
          </div>
        );
      },
    },
    {
      Header: 'Attributes',
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.stopPropagation();
              setIsOpenAttributesModal(true);
              setProductAttributesData(row?.original?.product?.attributes);
            }}
          >
            Attributes
          </div>
        );
      },
    },
  ];
  const dataMemo = useMemo(() => {
    return filteredInventoryData ?? [];
  }, [filteredInventoryData]);

  const columnMemo = useMemo(() => {
    return columns ?? [];
  }, [columns]);

  return (
    <PageWithCard heading="Universal Search">
      <ProductAdditionalSkuModal
        isOpenAdditionalSkuModel={isOpenAdditionalSkuModel}
        setIsOpenAdditionalSkuModel={setIsOpenAdditionalSkuModel}
        productAdditionalSkuData={productAdditionalSkuData}
      />
      <ProductAttributesModal
        isOpenAttributesModal={isOpenAttributesModal}
        setIsOpenAttributesModal={setIsOpenAttributesModal}
        productAttributesData={productAttributesData}
      />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3">
        <FormikAsyncSelect
          name="parentSku"
          label="Select Parent Sku"
          formik={formik}
          getOptions={async value => {
            const string = QueryString.stringify({ search: value });
            const productsResponse = await authAxiosInstance.get(`product?${string}`);
            const labels = productsResponse?.data?.data?.docs?.map((el, i) => {
              return el?.parentSku;
            });
            const parentSkuData = [...new Set(labels)];

            return parentSkuData.map(el => ({ label: el, value: el }));
          }}
          onChange={async selectedOption => {
            const string = QueryString.stringify({
              parentSku: selectedOption.value,
            });
            const productsResponse = await authAxiosInstance.get(`product?${string}`);
            console.log(productsResponse, 'productsResponse');
            const idArr = productsResponse?.data?.data?.docs.map(ele => ele._id);
            formik.setFieldValue('products', idArr);
            formik.setFieldValue('parentSku', selectedOption.value);
            console.log(productsResponse, 'resp');
          }}
        />
        {!formik?.values?.parentSku && (
          <FormikAsyncMultiSelect
            getOptions={async value => {
              const string = QueryString.stringify({ search: value });
              const productsResponse = await authAxiosInstance.get(`product?${string}`);
              const options = productsResponse?.data?.data?.docs?.map((el, i) => {
                return {
                  label: `${el?.name} / ${el?.masterSku}`,
                  value: el?._id,
                };
              });
              return options;
            }}
            name={`products`}
            formik={formik}
            label="Search Product"
          />
        )}
        <div>
          {formik.isSubmitting ? (
            <ClipLoader />
          ) : (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          )}
        </div>
      </form>
      {isLoading ? (
        <ClipLoader />
      ) : (
        <div className="mt-3">
          <TableWithHeadingAndSearch data={dataMemo} columns={columnMemo} />
        </div>
      )}
    </PageWithCard>
  );
};

export default UniversalSearch;
